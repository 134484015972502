import Flickity from 'flickity';
import FlickitySync from 'flickity-sync';

export default function () {
    var flkty = new Flickity('[data-carousel="liquid_news"]', {
       // asNavFor: '[data-carousel="liquid_news-media"]',
        sync: '[data-carousel="liquid_news-media"]',
         cellAlign: "left",
         percentPosition: false,
         contain: true,
         prevNextButtons: false,
         setGallerySize: false,
         pageDots: true
      });
      
      var flkty2 = new Flickity('[data-carousel="liquid_news-media"]', {
       // asNavFor: '[data-carousel="liquid_news"]',
       // sync: '[data-carousel="liquid_news"]',
         cellAlign: "left",
         percentPosition: false,
         contain: true,
         prevNextButtons: false,
         setGallerySize: false,
         pageDots: false
      });
}