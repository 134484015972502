import {} from "intersection-observer";
import observerHome from './observerScrollOpaqueHome';
import observerHome_2 from './observerScrollOpaqueHome_2';
import flickityNews from './flickityNews';


document.onreadystatechange = function () {
    switch (document.readyState) {
        case "loading":
            // Das Dokument wird noch geladen.
            break;
        case "interactive":
            // Das Dokument wurde geladen. Wir können nun die DOM-Elemente ansprechen.
            break;
        case "complete":
            // Die Seite ist komplett geladen.
            console.log('document.readyState.complete');
            objectFitPolyfill();
            observerHome();
            observerHome_2();
      /*     $("video").volume = .5;
           $("video").prop('muted', () => {return false}); //mute
        */
            break;
    }
}
