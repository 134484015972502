import scrollama from 'scrollama';



export default function () {

    // Get the required DOM elements
   // let steps = document.querySelectorAll('[data-js="scrollopaque-1"] [data-scrollama-step]');
    let steps = document.querySelectorAll('[data-js="scrollopaque-1"] [data-scrollopaque-step]');
    console.log(steps);

    //let imgs = document.querySelectorAll('[data-js="scrollopaque-1"] [data-scrollama-target]')
    let imgs = document.querySelectorAll('[data-js="scrollopaque-1"] [data-scrollopaque-sticky] img')
    console.log(imgs);

   // let cover = document.querySelector('[data-js="scrollopaque-1"] [data-stack-cover]')
   let cover = document.querySelector('[data-js="scrollopaque-1"] [data-scrollopaque-cover]')
   console.log(cover);


    // initialize the scrollama
    var scroller = scrollama();

    // scrollama event handlers
    function handleStepEnter(response) {
        // response = { element, direction, index }
        console.log('enter:' + response.index)
        if (response.direction === 'down') {
            Array.prototype.map.call(imgs, (img) => {
             //   console.log(img)
               imgs[response.index].classList.toggle('transparent', false)
              //  imgs[response.index].style.position = 'sticky';
             //   imgs[response.index].style.top = '0';
          ///   imgs[response.index].setAttribute('src',"localhost:3000/wp-content/uploads/2019/09/vw_das_lebende_buch_02_quadrat.jpg")

            });
        }
    }

    function handleStepExit(response) {
        console.log('exit:' + response.index)

        if (response.direction === 'up') {
            if (response.index == 0) {
            //    cover.classList.toggle('transparent')
            }
            if (!response.index == 0) {
            Array.prototype.map.call(imgs, (img) => {
                imgs[(response.index)].classList.toggle('transparent', true)
            });
        }
        }
    }

    function init() {
        // 1. force a resize on load to ensure proper dimensions are sent to scrollama
        //	handleResize();
        // 2. setup the scroller passing options
        // 		this will also initialize trigger observations
        // 3. bind scrollama event handlers (this can be chained like below)
        scroller.setup({
                step: steps,
                offset: .975,
                debug: false,
            })
            .onStepEnter(handleStepEnter)
            .onStepExit(handleStepExit)

    }
    // kick things off
    init();
}
